//$FilePathRelativeToProjectRoot$ --out-dir . --source-maps  --presets /usr/lib/node_modules/babel-preset-es2015
//$FileDirRelativeToProjectRoot$/$FileNameWithoutExtension$.js:$FileDirRelativeToProjectRoot$/$FileNameWithoutExtension$.js.map
Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a tabindex="0">Zpět</a></li>';
// Foundation.Drilldown.defaults.autoHeight = true;
// Foundation.Drilldown.defaults.animateHeight = true;
Foundation.Equalizer.defaults.equalizeOnStack = false;

$(document).foundation();

let vide_options = {
    poster: 'http://www.bezpecnenasilnicich.cz/files/images/header_Bg',
    posterType: 'jpg'
};

if (Foundation.MediaQuery.atLeast('medium')) {
    vide_options.mp4 = '/files/videos/header';
}

$('body').vide(
    vide_options
);

AOS.init();

lightbox.option(
    {
        'albumLabel': '%1 / %2',
    }
);


/*let $youtubeSlider = $('#youtube_slider');
 $youtubeSlider.slick(
 {
 lazyLoad: 'ondemand',
 dots: true,
 // 		arrows       : false,
 autoplay: true,
 autoplaySpeed: 5000
 }
 );


 $youtubeSlider.find('a')
 .on('mousedown', function (e) {
 $(this).data('moving', false);
 })
 .on('mousemove', function (e) {
 $(this).data('moving', true);
 })
 .on('mouseup', function (e) {
 if (!$(this).data('moving')) {
 let $that = $(this);
 e.preventDefault();
 $.post($that.data('href'), null, function (data) {
 $('#video_modal').html(data).foundation('open');
 });
 }
 });*/

$('[data-ajax-load]').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let href = $this.attr('href');

    $.post(href, null, function (data) {
        $('#universalModal').html(data).foundation('open');
    });
});

$('.formular-view-3 .wrap')
    .each(function () {
        let $that = $(this);
        let label = $that.children('.formInput-Label').remove();
        let placeholder = label.text().trim().toUpperCase();
        $that.find('input, textarea').attr('placeholder', placeholder).attr('required', 'required');
    });


const resizeBgImage = () => {
    const bg_height = $('.heading_section:eq(1)').offset().top;
    /*$('body').css('background-size', `auto ${bg_height}px`);*/

    $('body > div').first().css({
        height: bg_height,
        bottom: 'auto'
    });
};


const isInView = ($elem, threshold = 0) => {
    let elementTop = $elem.offset().top;
    let elementBottom = elementTop + $elem.outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return (elementBottom + threshold) > viewportTop && (elementTop - threshold) < viewportBottom;
};


$('.main_wrap').each(function () {
    let $this = $(this);

    let url = $this.data('image');

    if (url) {
        $this.prepend($('<div/>', {
            'class': 'bg_pseudo'
        }));
        $this.children('.bg_pseudo').css('background-image', `url(${url})`);
    }
});

const $window = $(window);

const $menu19 = $('.menu_19');

const $links = $menu19.find('.links');
const $backToTop = $('#backToTop');

$('.home_sponsor_logos_outer_wrap').addClass('antiskewed');

//region obrázek na pozadí
$(window).on('resize', () => {
    resizeBgImage();
});

$(document).on('ready', () => {
    resizeBgImage();
});
//endregion

$backToTop.on('click', e => {
    $('html, body').animate({scrollTop: 0}, '500', 'swing');
});

$window.on('scroll', () => {
    if (isInView($menu19)) { // Sticky social menu
        $links.removeClass('unplugged');
    } else {
        $links.addClass('unplugged');
    }


    if ($(window).scrollTop() > 1000) {
        $backToTop.removeClass('hiding');
    } else {
        $backToTop.addClass('hiding');
    }
});